// entities.js
export const STATE_ENTITIES = {

  AUTH: {
    LOGIN: "login",
    REGISTER: "register",
  },
  ADMIN: {
    DASHBOARD: "dashboard",
  },
  USER: {
    READ: "user_read",
    CREATE: "user_create",
    UPDATE: "user_update",
    DELETE: "user_delete",
  },
  DESIGNATION: {
    READ: "designation_read",
    CREATE: "designation_create",
    UPDATE: "designation_update",
    DELETE: "designation_delete",
  },
  PAGE: {
    READ: "page_read",
    CREATE: "page_create",
    UPDATE: "page_update",
    DELETE: "page_delete",
  },

  STATUS_CATEGORY: {
    READ: "statusCategory_read",
    CREATE: "statusCategory_create",
    UPDATE: "statusCategory_update",
    DELETE: "statusCategory_delete",
  },

  CITY: {
    READ_ALL: "city_read_all",
   
  },

  STATE: {
    READ_ALL: "state_read_all",
   
  },

  GLOBAL: {
    VENUE_CONFIG: "venue_config",
    ANC_CONFIG: "anc_config",
    BANK_LIST: "bank_list",
    PLAN_LIST: "plan_list",
    COUNTRY_STATE_CITY: "country_state_city",



  },

};
