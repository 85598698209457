// import { lazy } from "react";
// import Loadable from "components/Loadable";
// import { authRoles } from "auth/authRoles";
// import { pluralize } from "utils/utils";

// // Utility function to generate CRUD routes based on options
// const createCrudRoutes = (basePath, List, AddEdit, View, auth, options = {}) => {
//   const routes = [];
//   if (options.list) routes.push({ path: pluralize(basePath), element: <List />, auth });
//   if (options.add) routes.push({ path: `${basePath}/add`, element: <AddEdit />, auth });
//   if (options.edit) routes.push({ path: `${basePath}/edit/:id`, element: <AddEdit />, auth });
//   if (options.view) routes.push({ path: `${basePath}/view/:id`, element: <View />, auth });
//   return routes;
// };

// // Lazy-loaded components
// const Analytics = Loadable(lazy(() => import("pages/dashboard/Analytics")));
// const NotFound = Loadable(lazy(() => import("pages/error/NotFound")));

// // USER
// const UserList = Loadable(lazy(() => import("pages/private/user/List")));
// const UserAddEdit = Loadable(lazy(() => import("pages/private/user/AddEdit")));
// const UserView = Loadable(lazy(() => import("pages/private/user/View")));

// // DESIGNATION
// const DesignationList = Loadable(lazy(() => import("pages/private/designation/List")));
// const DesignationAddEdit = Loadable(lazy(() => import("pages/private/designation/AddEdit")));
// const DesignationView = Loadable(lazy(() => import("pages/private/designation/View")));

// // CMS_PAGE
// const CMSPageList = Loadable(lazy(() => import("pages/private/cms-page/List")));
// const CMSPageAddEdit = Loadable(lazy(() => import("pages/private/cms-page/AddEdit")));
// const CMSPageView = Loadable(lazy(() => import("pages/private/cms-page/View")));

// // STATUS CATEGORY
// const StatusCategoryList = Loadable(lazy(() => import("pages/private/status-category/List")));
// const StatusCategoryAddEdit = Loadable(lazy(() => import("pages/private/status-category/AddEdit")));
// const StatusCategoryView = Loadable(lazy(() => import("pages/private/status-category/View")));

// // ROLE
// const RoleList = Loadable(lazy(() => import("pages/private/role/List")));
// const RoleAddEdit = Loadable(lazy(() => import("pages/private/role/AddEdit")));
// const RoleView = Loadable(lazy(() => import("pages/private/role/View")));

// // VENUE
// const VenueList = Loadable(lazy(() => import("pages/private/venue/List")));
// const VenueAddEdit = Loadable(lazy(() => import("pages/private/venue/AddEdit")));
// const VenueView = Loadable(lazy(() => import("pages/private/venue/View")));


// // Transaction
// const TransactionList = Loadable(lazy(() => import("pages/private/transaction/List")));
// const TransactionAddEdit = Loadable(lazy(() => import("pages/private/transaction/AddEdit")));
// const TransactionView = Loadable(lazy(() => import("pages/private/transaction/View")));


// // ANCILLARY SERVICES
// const AncillaryServiceList = Loadable(lazy(() => import("pages/private/ancillary-service/List")));
// const AncillaryServiceAddEdit = Loadable(lazy(() => import("pages/private/ancillary-service/AddEdit")));
// const AncillaryServiceView = Loadable(lazy(() => import("pages/private/ancillary-service/View")));


// // APPROVAL
// const ApprovalList = Loadable(lazy(() => import("pages/private/approval/List")));
// const ApprovalAddEdit = Loadable(lazy(() => import("pages/private/approval/AddEdit")));
// const ApprovalView = Loadable(lazy(() => import("pages/private/approval/View")));


// // VENUE FACILITY Facility
// const VenueFacilityList = Loadable(lazy(() => import("pages/private/venue-facility/List")));
// const VenueFacilityAddEdit = Loadable(lazy(() => import("pages/private/venue-facility/AddEdit")));
// const VenueFacilityView = Loadable(lazy(() => import("pages/private/venue-facility/View")));


// // VENUE TYPE
// const VenueTypeList = Loadable(lazy(() => import("pages/private/venue-type/List")));
// const VenueTypeAddEdit = Loadable(lazy(() => import("pages/private/venue-type/AddEdit")));
// const VenueTypeView = Loadable(lazy(() => import("pages/private/venue-type/View")));





// // EMAIL TEMPLATE
// const EmailTemplateList = Loadable(lazy(() => import("pages/private/email-template/List")));
// const EmailTemplateAddEdit = Loadable(lazy(() => import("pages/private/email-template/AddEdit")));
// const EmailTemplateView = Loadable(lazy(() => import("pages/private/email-template/View")));





// // VENUE
// const AncillaryServiceCategoryList = Loadable(lazy(() => import("pages/private/ancillary-services-category/List")));
// const AncillaryServiceCategoryAddEdit = Loadable(lazy(() => import("pages/private/ancillary-services-category/AddEdit")));
// const AncillaryServiceCategoryView = Loadable(lazy(() => import("pages/private/ancillary-services-category/View")));


// // BANK
// const BankList = Loadable(lazy(() => import("pages/private/bank/List")));
// const BankAddEdit = Loadable(lazy(() => import("pages/private/bank/AddEdit")));
// const BankView = Loadable(lazy(() => import("pages/private/bank/View")));


// // PLAN
// const PlanList = Loadable(lazy(() => import("pages/private/plan/List")));
// const PlanAddEdit = Loadable(lazy(() => import("pages/private/plan/AddEdit")));
// const PlanView = Loadable(lazy(() => import("pages/private/plan/View")));


// // // VENUE
// // const VenueList = Loadable(lazy(() => import("pages/private/venue/List")));
// // const VenueAddEdit = Loadable(lazy(() => import("pages/private/venue/AddEdit")));
// // const VenueView = Loadable(lazy(() => import("pages/private/venue/View")));


// // TESTIMONIAL
// const TestimonialList = Loadable(lazy(() => import("pages/private/testimonial/List")));
// const TestimonialAddEdit = Loadable(lazy(() => import("pages/private/testimonial/AddEdit")));
// const TestimonialView = Loadable(lazy(() => import("pages/private/testimonial/View")));

// // BROADCAST
// const BroadcastList = Loadable(lazy(() => import("pages/private/broadcast/List")));
// const BroadcastAddEdit = Loadable(lazy(() => import("pages/private/broadcast/AddEdit")));
// const BroadcastView = Loadable(lazy(() => import("pages/private/broadcast/View")));


// // SUPPORT
// const SupportList = Loadable(lazy(() => import("pages/private/support/List")));
// const SupportAddEdit = Loadable(lazy(() => import("pages/private/support/AddEdit")));
// const SupportView = Loadable(lazy(() => import("pages/private/support/View")));


// // CONTACT US
// const ContactUsList = Loadable(lazy(() => import("pages/private/contact-us/List")));
// const ContactUsAddEdit = Loadable(lazy(() => import("pages/private/contact-us/AddEdit")));
// const ContactUsView = Loadable(lazy(() => import("pages/private/contact-us/View")));


// // NEWSLETTER
// const NewsletterList = Loadable(lazy(() => import("pages/private/newsletter/List")));
// const NewsletterAddEdit = Loadable(lazy(() => import("pages/private/newsletter/AddEdit")));
// const NewsletterView = Loadable(lazy(() => import("pages/private/newsletter/View")));










// const privateRoutes = [
//   { path: "/dashboard", element: <Analytics />, auth: authRoles.admin },
//   { path: "*", element: <NotFound /> },


//   ...createCrudRoutes("/user", UserList, UserAddEdit, UserView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
//   ...createCrudRoutes("/cms-page", CMSPageList, CMSPageAddEdit, CMSPageView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
//   ...createCrudRoutes("/designation", DesignationList, DesignationAddEdit, DesignationView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
//   ...createCrudRoutes("/status-category", StatusCategoryList, StatusCategoryAddEdit, StatusCategoryView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
//   ...createCrudRoutes("/role", RoleList, RoleAddEdit, RoleView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
//   ...createCrudRoutes("/venue", VenueList, VenueAddEdit, VenueView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  


//   // Add more CRUD route sets as needed using createCrudRoutes with different options



// ];

// export default privateRoutes;

import { lazy } from "react";
import Loadable from "components/Loadable";
import { authRoles } from "auth/authRoles";
import { pluralize } from "utils/utils";

// Utility function to generate CRUD routes based on options
const createCrudRoutes = (basePath, List, AddEdit, View, auth, options = {}) => {
  const routes = [];
  if (options.list) routes.push({ path: pluralize(basePath), element: <List />, auth });
  if (options.add) routes.push({ path: `${basePath}/add`, element: <AddEdit />, auth });
  if (options.edit) routes.push({ path: `${basePath}/edit/:id`, element: <AddEdit />, auth });
  if (options.view) routes.push({ path: `${basePath}/view/:id`, element: <View />, auth });
  return routes;
};

// Lazy-loaded components
const Analytics = Loadable(lazy(() => import("pages/dashboard/Analytics")));
const Dashboard = Loadable(lazy(() => import("pages/dashboard/dashboard")));
const NotFound = Loadable(lazy(() => import("pages/error/NotFound")));

// USER
const UserList = Loadable(lazy(() => import("pages/private/user/List")));
const UserAddEdit = Loadable(lazy(() => import("pages/private/user/AddEdit")));
const UserView = Loadable(lazy(() => import("pages/private/user/View")));

// DESIGNATION
const DesignationList = Loadable(lazy(() => import("pages/private/designation/List")));
const DesignationAddEdit = Loadable(lazy(() => import("pages/private/designation/AddEdit")));
const DesignationView = Loadable(lazy(() => import("pages/private/designation/View")));

// CMS_PAGE
const CMSPageList = Loadable(lazy(() => import("pages/private/cms-page/List")));
const CMSPageAddEdit = Loadable(lazy(() => import("pages/private/cms-page/AddEdit")));
const CMSPageView = Loadable(lazy(() => import("pages/private/cms-page/View")));

// STATUS CATEGORY
const StatusCategoryList = Loadable(lazy(() => import("pages/private/status-category/List")));
const StatusCategoryAddEdit = Loadable(lazy(() => import("pages/private/status-category/AddEdit")));
const StatusCategoryView = Loadable(lazy(() => import("pages/private/status-category/View")));

// ROLE
const RoleList = Loadable(lazy(() => import("pages/private/role/List")));
const RoleAddEdit = Loadable(lazy(() => import("pages/private/role/AddEdit")));
const RoleView = Loadable(lazy(() => import("pages/private/role/View")));

// VENUE
const VenueList = Loadable(lazy(() => import("pages/private/venue/List")));
const VenueAddEdit = Loadable(lazy(() => import("pages/private/venue/AddEdit")));
const VenueView = Loadable(lazy(() => import("pages/private/venue/View")));

// TRANSACTION
const TransactionList = Loadable(lazy(() => import("pages/private/transaction/List")));
const TransactionAddEdit = Loadable(lazy(() => import("pages/private/transaction/AddEdit")));
const TransactionView = Loadable(lazy(() => import("pages/private/transaction/View")));

// ANCILLARY SERVICES
const AncillaryServiceList = Loadable(lazy(() => import("pages/private/ancillary-service/List")));
const AncillaryServiceAddEdit = Loadable(lazy(() => import("pages/private/ancillary-service/AddEdit")));
const AncillaryServiceView = Loadable(lazy(() => import("pages/private/ancillary-service/View")));

// APPROVAL
const ApprovalList = Loadable(lazy(() => import("pages/private/approval/List")));
const ApprovalAddEdit = Loadable(lazy(() => import("pages/private/approval/AddEdit")));
const ApprovalView = Loadable(lazy(() => import("pages/private/approval/View")));

// VENUE FACILITY
const VenueFacilityList = Loadable(lazy(() => import("pages/private/venue-facility/List")));
const VenueFacilityAddEdit = Loadable(lazy(() => import("pages/private/venue-facility/AddEdit")));
const VenueFacilityView = Loadable(lazy(() => import("pages/private/venue-facility/View")));

// VENUE TYPE
const VenueTypeList = Loadable(lazy(() => import("pages/private/venue-type/List")));
const VenueTypeAddEdit = Loadable(lazy(() => import("pages/private/venue-type/AddEdit")));
const VenueTypeView = Loadable(lazy(() => import("pages/private/venue-type/View")));

// EMAIL TEMPLATE
const EmailTemplateList = Loadable(lazy(() => import("pages/private/email-template/List")));
const EmailTemplateAddEdit = Loadable(lazy(() => import("pages/private/email-template/AddEdit")));
const EmailTemplateView = Loadable(lazy(() => import("pages/private/email-template/View")));

// ANCILLARY SERVICES CATEGORY
const AncillaryServiceCategoryList = Loadable(lazy(() => import("pages/private/ancillary-services-category/List")));
const AncillaryServiceCategoryAddEdit = Loadable(lazy(() => import("pages/private/ancillary-services-category/AddEdit")));
const AncillaryServiceCategoryView = Loadable(lazy(() => import("pages/private/ancillary-services-category/View")));

// BANK
const BankList = Loadable(lazy(() => import("pages/private/bank/List")));
const BankAddEdit = Loadable(lazy(() => import("pages/private/bank/AddEdit")));
const BankView = Loadable(lazy(() => import("pages/private/bank/View")));

// PLAN
const PlanList = Loadable(lazy(() => import("pages/private/plan/List")));
const PlanAddEdit = Loadable(lazy(() => import("pages/private/plan/AddEdit")));
const PlanView = Loadable(lazy(() => import("pages/private/plan/View")));

// TESTIMONIAL
const TestimonialList = Loadable(lazy(() => import("pages/private/testimonial/List")));
const TestimonialAddEdit = Loadable(lazy(() => import("pages/private/testimonial/AddEdit")));
const TestimonialView = Loadable(lazy(() => import("pages/private/testimonial/View")));


// ABOUT
const AboutList = Loadable(lazy(() => import("pages/private/about/List")));
const AboutAddEdit = Loadable(lazy(() => import("pages/private/about/AddEdit")));
const AboutView = Loadable(lazy(() => import("pages/private/about/View")));


// TESTIMONIAL
const BannerList = Loadable(lazy(() => import("pages/private/banner/List")));
const BannerAddEdit = Loadable(lazy(() => import("pages/private/banner/AddEdit")));
const BannerView = Loadable(lazy(() => import("pages/private/banner/View")));


// ADVERT
const AdvertList = Loadable(lazy(() => import("pages/private/advertisement/List")));
const AdvertAddEdit = Loadable(lazy(() => import("pages/private/advertisement/AddEdit")));
const AdvertView = Loadable(lazy(() => import("pages/private/advertisement/View")));

// BROADCAST
const BroadcastList = Loadable(lazy(() => import("pages/private/broadcast/List")));
const BroadcastAddEdit = Loadable(lazy(() => import("pages/private/broadcast/AddEdit")));
const BroadcastView = Loadable(lazy(() => import("pages/private/broadcast/View")));

// SUPPORT
const SupportList = Loadable(lazy(() => import("pages/private/support/List")));
const SupportAddEdit = Loadable(lazy(() => import("pages/private/support/AddEdit")));
const SupportView = Loadable(lazy(() => import("pages/private/support/View")));

// CONTACT US
const ContactUsList = Loadable(lazy(() => import("pages/private/contact-us/List")));
const ContactUsAddEdit = Loadable(lazy(() => import("pages/private/contact-us/AddEdit")));
const ContactUsView = Loadable(lazy(() => import("pages/private/contact-us/View")));

// NEWSLETTER
const NewsletterList = Loadable(lazy(() => import("pages/private/newsletter/List")));
const NewsletterAddEdit = Loadable(lazy(() => import("pages/private/newsletter/AddEdit")));
const NewsletterView = Loadable(lazy(() => import("pages/private/newsletter/View")));


// SETTING
const SettingList = Loadable(lazy(() => import("pages/private/setting/List")));
const SettingAddEdit = Loadable(lazy(() => import("pages/private/setting/AddEdit")));
const SettingView = Loadable(lazy(() => import("pages/private/setting/View")));


// COUNTRY
const CountryList = Loadable(lazy(() => import("pages/private/country/List")));
const CountryAddEdit = Loadable(lazy(() => import("pages/private/country/AddEdit")));
const CountryView = Loadable(lazy(() => import("pages/private/country/View")));

// STATE
const StateList = Loadable(lazy(() => import("pages/private/state/List")));
const StateAddEdit = Loadable(lazy(() => import("pages/private/state/AddEdit")));
const StateView = Loadable(lazy(() => import("pages/private/state/View")));

// CITY
const CityList = Loadable(lazy(() => import("pages/private/city/List")));
const CityAddEdit = Loadable(lazy(() => import("pages/private/city/AddEdit")));
const CityView = Loadable(lazy(() => import("pages/private/city/View")));


// Define private routes
const privateRoutes = [
  { path: "/dashboard", element: <Analytics />, auth: authRoles.admin },
  // { path: "/dashboard/dashboard", element: <Dashboard />, auth: authRoles.admin },
  { path: "*", element: <NotFound /> },

  // User Routes
  ...createCrudRoutes("/user", UserList, UserAddEdit, UserView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
  ...createCrudRoutes("/setting", SettingList, SettingAddEdit, SettingView, authRoles.admin, { list: true, add: false, edit: false, view: false }),

  
  // CMS Page Routes
  ...createCrudRoutes("/page", CMSPageList, CMSPageAddEdit, CMSPageView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
  
  // Designation Routes
  ...createCrudRoutes("/designation", DesignationList, DesignationAddEdit, DesignationView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Status Category Routes
  ...createCrudRoutes("/status-category", StatusCategoryList, StatusCategoryAddEdit, StatusCategoryView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
  
  // Role Routes
  ...createCrudRoutes("/role", RoleList, RoleAddEdit, RoleView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
  
  // Venue Routes
  ...createCrudRoutes("/venue", VenueList, VenueAddEdit, VenueView, authRoles.admin, { list: true, add: true, edit: true, view: true }),
  
  // Transaction Routes
  ...createCrudRoutes("/transaction", TransactionList, TransactionAddEdit, TransactionView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Ancillary Service Routes
  ...createCrudRoutes("/ancillary-service", AncillaryServiceList, AncillaryServiceAddEdit, AncillaryServiceView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Approval Routes
  ...createCrudRoutes("/approval", ApprovalList, ApprovalAddEdit, ApprovalView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Venue Facility Routes
  ...createCrudRoutes("/venue-facility", VenueFacilityList, VenueFacilityAddEdit, VenueFacilityView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Venue Type Routes
  ...createCrudRoutes("/venue-type", VenueTypeList, VenueTypeAddEdit, VenueTypeView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Email Template Routes
  ...createCrudRoutes("/email-template", EmailTemplateList, EmailTemplateAddEdit, EmailTemplateView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Ancillary Service Category Routes
  ...createCrudRoutes("/ancillary-services-category", AncillaryServiceCategoryList, AncillaryServiceCategoryAddEdit, AncillaryServiceCategoryView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Bank Routes
  // ...createCrudRoutes("/bank", BankList, BankAddEdit, BankView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Plan Routes
  ...createCrudRoutes("/plan", PlanList, PlanAddEdit, PlanView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Testimonial Routes
  ...createCrudRoutes("/testimonial", TestimonialList, TestimonialAddEdit, TestimonialView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

    // About Routes
    ...createCrudRoutes("/about", AboutList, AboutAddEdit, AboutView, authRoles.admin, { list: true, add: true, edit: true, view: true }),


  // Banner Routes
  ...createCrudRoutes("/banner", BannerList, BannerAddEdit, BannerView, authRoles.admin, { list: true, add: true, edit: true, view: true }),


  // Advertisement Routes
  ...createCrudRoutes("/advertisement", AdvertList, AdvertAddEdit, AdvertView, authRoles.admin, { list: true, add: true, edit: true, view: true }),


  // Broadcast Routes
  ...createCrudRoutes("/broadcast", BroadcastList, BroadcastAddEdit, BroadcastView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Support Routes
  ...createCrudRoutes("/support", SupportList, SupportAddEdit, SupportView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Contact Us Routes
  ...createCrudRoutes("/contact-us", ContactUsList, ContactUsAddEdit, ContactUsView, authRoles.admin, { list: true, add: true, edit: true, view: true }),

  // Newsletter Routes
  ...createCrudRoutes("/newsletter", NewsletterList, NewsletterAddEdit, NewsletterView, authRoles.admin, { list: true, add: true, edit: true, view: true }),


  // Country Routes
  ...createCrudRoutes("/country", CountryList, CountryAddEdit, CountryView, authRoles.admin, { list: true, add: false, edit: false, view: false }),

  // State Routes
  ...createCrudRoutes("/state", StateList, StateAddEdit, StateView, authRoles.admin, { list: true, add: false, edit: false, view: false }),

  // City Routes
  ...createCrudRoutes("/city", CityList, CityAddEdit, CityView, authRoles.admin, { list: true, add: false, edit: false, view: false }),
];

export default privateRoutes;

