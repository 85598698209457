import axios from 'axios';
import { setLoading } from 'store/features/loaderSlice';
import { CODE, StorageConstant } from 'constants/CommonConstants';
import { REACT_APP_API_BASE_URL } from 'config/Config';
import {store} from 'store/store';
import { getBrowserTimezone } from 'utils/dateTimeHelper';
// import { refreshToken } from 'services/authService';
import { showSnackbar } from 'store/features/snackBarSlice';
import { getItemFromStorage } from 'utils/storageHelper';



// Create Axios instance
const apiInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
    'timezone': getBrowserTimezone(),
  },
});

// Add request interceptor
apiInstance.interceptors.request.use(
  (config) => {
   
    const token = getItemFromStorage(StorageConstant?.is_admin_access_token);
    // Configure Toast
   if(config.data instanceof FormData){

    config.headers['toastStatus'] = config?.data?.get('toastStatus');   
     config?.data?.delete('toastStatus')

   }else{
    config.headers['toastStatus'] = config?.data?.toastStatus;   
    delete config?.data?.toastStatus
   }

    

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }


    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';

    }
    if(config?.data?.apiLoading){
      store?.dispatch(setLoading(true)); // Dispatch action to show loade
    
    }
    delete config?.data?.apiLoading

    return config;
  },
  (error) => {
    store?.dispatch(setLoading(false)); // Dispatch action to hide loader
    return Promise.reject(error);
  }
);

// Add response interceptor
apiInstance.interceptors.response.use(
  (response) => {
    const headers =  response.config.headers
    const toastStatus = headers.toastStatus === "true" ? true : false


   
    store?.dispatch(setLoading(false)); // Dispatch action to hide loader
    if (response.status === CODE.OK_CODE || response.status === CODE.CREATED) {
      // if(response.data.message)
      if(toastStatus){
        if(response.data.status){
          store?.dispatch(showSnackbar({ message: response.data.message, severity: 'success' }));
        }else {
          store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
        }
      }
      return response.data;
    } else if (response.status === CODE.BAD_REQUEST_CODE) {
      store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
      return Promise.reject(response.data);
    } else if (response.status === CODE.INTERNAL_SERVER_ERROR) {
      store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
      return Promise.reject(response.data);
    } else if (response.status === CODE.VALIDATION_CODE) {
      store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
      return Promise.reject(response.data);
    } else if (response.status === CODE.UNAUTHORIZED_CODE) {
      // store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
      // sessionStorage.clear();
      // window.location.href = '/sign-in';
      return Promise.reject(response.data);
    } else {
      store?.dispatch(showSnackbar({ message: response.data.message, severity: 'error' }));
      return Promise.reject(new Error('An unexpected error occurred'));
    }
  }, 
  async (error) => {
    console.log("🚀 ~ error:", error)
    store?.dispatch(setLoading(false)); // Dispatch action to hide loader
    if (!error.response) {
      if (error.message && (error.message.includes('Network Error') || error.message.includes('timeout'))) {
        // Handle network issues
        store?.dispatch(showSnackbar({ message: 'Please check your internet connection and try again.', severity: 'error' }));
      } else {
        // Handle other errors
        store?.dispatch(showSnackbar({ message: 'An unexpected error occurred. Please try again later.', severity: 'error' }));
      }
    } else if (error.response.status === CODE.SERVICE_UNAVAILABLE_CODE) {
      // Handle server downtime
      store?.dispatch(showSnackbar({ message: 'The API server is currently unavailable. Please try again later.', severity: 'error' }));
    }   else {
      if(error.response.data?.code === CODE.FORBIDDEN_CODE){
       store?.dispatch(showSnackbar({ message: "Access Denied: Unauthorized access.", severity: 'error' }));
       setTimeout(() => {window.location.href = '/';}, 500)
      }
      if(error.response.data?.code === CODE.UNAUTHORIZED_CODE){
      
       store?.dispatch(showSnackbar({ message: "Please authenticate.", severity: 'error' }));
       setTimeout(() => {window.location.href = '/session/signin';}, 500)
      }else{
       store?.dispatch(showSnackbar({ message: error?.message == "Request failed with status code 500" ? "Something went wrong. Please try again later."  : error.response.data?.message, severity: 'error' }));
 
      }
     }
    return Promise.reject(error);
  
  }
);

export default apiInstance;

