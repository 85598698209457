import { HTTP_METHODS } from "constants/CommonConstants";
import { TOAST_STATUS ,API_LOADING } from "utils/constant";

// Define base URLs
const BASE_URLS = {
  AUTH: "auth",
  ADMIN: "dashboard",
  DASHBOARD: "dashboard",

  PROFILE: "profile",
  PAGE: "page",
  DESIGNATION: "designation",
  USER: "user",
  STATUS_CATEGORY: "statusCategory",
  EMAIL_TEMPLATE: "emailTemplate",
  TESTIMONIAL: "testimonial",
  ADVERTISEMENT: "advertisement",
  BANNER: "banner",
  COUNTRY: "country",

  STATE: "state",
  CITY: "city",
  VENUE_TYPE: "venueType",
  VENUE_FACILITY: "venueFacility",
  ABOUT: "about",
  ANCILLIARY_SERVICE_CATEGORY: "ancilliaryServiceCategory",
  BANK: "bank",
  PLAN: "plan",
  ANCILLARY_SERVICE: "ancillaryService",
  GLOBAL: "global",
  VENUE: "venue",
  ADMIN_SETTING : "adminSetting"



};

// Function to create endpoint object
const createEndpoint = (url, method , toastStatus = false , apiLoading =false) => ({ url, method ,toastStatus ,apiLoading});

// Function to generate CRUD endpoints with options
const generateCrudEndpoints = (baseUrl, options , apis) => {
  const endpoints = {};



  if (options.read) {
    endpoints.READ = createEndpoint(`${baseUrl}`, HTTP_METHODS.GET ,TOAST_STATUS.ON,API_LOADING.OFF);
  }

  if (options.create) {
    endpoints.CREATE = createEndpoint(`${baseUrl}`, HTTP_METHODS.POST ,TOAST_STATUS.ON,API_LOADING.OFF);
  }

  if (options.update) {
    endpoints.UPDATE = createEndpoint(`${baseUrl}/:id`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON,API_LOADING.OFF);
  }

  
  if (options.delete) {
    endpoints.DELETE = createEndpoint(`${baseUrl}/:id`, HTTP_METHODS.DELETE ,TOAST_STATUS.ON,API_LOADING.OFF);
  }

  if (options.read_one) {
    // Assuming READ_ONE means fetching a single item
    endpoints.READ_ONE = createEndpoint(`${baseUrl}/:id`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.ON);
  }

  if(apis){
    for(let apiKey in apis){
      endpoints[apiKey] = apis[apiKey]

    }
  }

  return endpoints;
};

// API Configuration object with options
export const ApiConfig = {
  AUTH: {
    LOGIN: createEndpoint(`${BASE_URLS.AUTH}/login`, HTTP_METHODS.POST ,TOAST_STATUS.ON,API_LOADING.OFF),
    REGISTER: createEndpoint(`${BASE_URLS.AUTH}/register`, HTTP_METHODS.POST ,TOAST_STATUS.ON,API_LOADING.OFF),
    OTP: createEndpoint(`${BASE_URLS.AUTH}/login`, HTTP_METHODS.POST , TOAST_STATUS.ON,API_LOADING.OFF),
    FORGOT: createEndpoint(`${BASE_URLS.AUTH}/login`, HTTP_METHODS.POST ,TOAST_STATUS.ON,API_LOADING.OFF),
    CHANGE_PASSWORD: createEndpoint(`${BASE_URLS.AUTH}/change-password`, HTTP_METHODS.POST ,TOAST_STATUS.ON,API_LOADING.OFF),

  },
  ADMIN: {
    DASHBOARD: createEndpoint(`${BASE_URLS.GLOBAL}/dashboard` , HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),
  },
  GLOBAL: {
    VENUE_CONFIG: createEndpoint(`${BASE_URLS.GLOBAL}/venue-configuration`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),
    BANK_LIST: createEndpoint(`${BASE_URLS.GLOBAL}/bank-list`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),
    ANC_CONFIG: createEndpoint(`${BASE_URLS.GLOBAL}/anc-configuration`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),
    PLAN_LIST: createEndpoint(`${BASE_URLS.GLOBAL}/plan-list`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),
    COUNTRY_STATE_CITY: createEndpoint(`${BASE_URLS.GLOBAL}/country-state-city`, HTTP_METHODS.GET ,TOAST_STATUS.OFF,API_LOADING.OFF),




  },
  VENUE: generateCrudEndpoints(`${BASE_URLS.VENUE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.VENUE}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
    CHANGE_APPROVE_STATUS: createEndpoint(`${BASE_URLS.VENUE}/:id/approve-status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
    MAKE_FEATURE: createEndpoint(`${BASE_URLS.VENUE}/:id/make-feature`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),

    DELETE_IMAGE: createEndpoint(`${BASE_URLS.VENUE}/:id/image`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.ON),


  }
  ),
  PROFILE: generateCrudEndpoints(`${BASE_URLS.PROFILE}`, {
    read: true,
    read_plural: true,  // Example of using read_plural option for pluralized read URL
    create: true,
    update: true,
    delete: true,
    read_one: false,
  },
  
  {
    CHANGE_PASSWORD: createEndpoint(`${BASE_URLS.PROFILE}/change-password`, HTTP_METHODS.POST),
  })
  ,
  
  PAGE: generateCrudEndpoints(`${BASE_URLS.PAGE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
  EMAIL_TEMPLATE: generateCrudEndpoints(`${BASE_URLS.EMAIL_TEMPLATE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
  TESTIMONIAL: generateCrudEndpoints(`${BASE_URLS.TESTIMONIAL}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
  ABOUT: generateCrudEndpoints(`${BASE_URLS.ABOUT}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
  ADVERTISEMENT: generateCrudEndpoints(`${BASE_URLS.ADVERTISEMENT}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),

  VENUE_TYPE: generateCrudEndpoints(`${BASE_URLS.VENUE_TYPE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.VENUE_TYPE}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ,
  ),


  VENUE_FACILITY: generateCrudEndpoints(`${BASE_URLS.VENUE_FACILITY}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.VENUE_FACILITY}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
 



  BANNER: generateCrudEndpoints(`${BASE_URLS.BANNER}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
  STATE: generateCrudEndpoints(`${BASE_URLS.STATE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    STATE_ALL: createEndpoint(`${BASE_URLS.STATE}/all`, HTTP_METHODS.GET),
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.STATE}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),

  }
  ,
  ),

  CITY: generateCrudEndpoints(`${BASE_URLS.CITY}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.CITY}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
  COUNTRY: generateCrudEndpoints(`${BASE_URLS.COUNTRY}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.COUNTRY}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
  BANK: generateCrudEndpoints(`${BASE_URLS.BANK}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.BANK}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
  PLAN: generateCrudEndpoints(`${BASE_URLS.PLAN}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.PLAN}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
  ANCILLARY_SERVICE: generateCrudEndpoints(`${BASE_URLS.ANCILLARY_SERVICE}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.ANCILLARY_SERVICE}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
    CHANGE_APPROVE_STATUS: createEndpoint(`${BASE_URLS.ANCILLARY_SERVICE}/:id/approve-status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
    DELETE_IMAGE: createEndpoint(`${BASE_URLS.ANCILLARY_SERVICE}/:id/image`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.ON),
    MAKE_FEATURE: createEndpoint(`${BASE_URLS.ANCILLARY_SERVICE}/:id/make-feature`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),


  }
  ),


  ANCILLIARY_SERVICE_CATEGORY: generateCrudEndpoints(`${BASE_URLS.ANCILLIARY_SERVICE_CATEGORY}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.ANCILLIARY_SERVICE_CATEGORY}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
  }
  ),
  USER: generateCrudEndpoints(`${BASE_URLS.USER}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    USER_GET_BY_ROLES: createEndpoint(`${BASE_URLS.USER}/getByRoles`, HTTP_METHODS.POST),
    CHANGE_STATUS: createEndpoint(`${BASE_URLS.USER}/:id/status`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),
    UPDATE_FLOW: createEndpoint(`${BASE_URLS.USER}/:id/flow`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),

  }
  ),

  ADMIN_SETTING: generateCrudEndpoints(`${BASE_URLS.ADMIN_SETTING}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  },
  {
    UPDATE_FLOW: createEndpoint(`${BASE_URLS.ADMIN_SETTING}/:id/flow`, HTTP_METHODS.PATCH ,TOAST_STATUS.ON ,API_LOADING.OFF),

  }
  ),
  DESIGNATION: generateCrudEndpoints(`${BASE_URLS.DESIGNATION}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: false,
  }),
  STATUS_CATEGORY: generateCrudEndpoints(`${BASE_URLS.STATUS_CATEGORY}`, {
    read: true,
    create: true,
    update: true,
    delete: true,
    read_one: true,
  }),
};


