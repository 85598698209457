import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MatxTheme from "components/MatxTheme";
import { store } from "./store/store";
import { Provider as ReduxProvider } from "react-redux";
import routes from "routes";
import { useRoutes } from "react-router-dom";
import SnackbarComponent from "components/Snackbar/Snackbar";


export default function App() {
  // routes configuration
  const content = useRoutes(routes);
  return (
    <ReduxProvider store={store}>
      <MatxTheme>
        <CssBaseline />
        {content}
      </MatxTheme>
      <SnackbarComponent />
    </ReduxProvider>
  );
}
