// adminService.js
import apiInstance from 'services/ApiService';
import { ApiConfig } from 'config/ApiConfig';
import { STATE_ENTITIES } from 'utils/entities';

export const getDashboardData = async (params) => {
  const { url, method } = ApiConfig.ADMIN.DASHBOARD;
  const response = await apiInstance({ url, method ,params});
  return { response, entity: STATE_ENTITIES.ADMIN.DASHBOARD };
};


export const readAncConfigService = async (params) => {
  const { url, method } = ApiConfig.GLOBAL.ANC_CONFIG;
  const response = await apiInstance({ url, method ,params });
  return { response, entity: STATE_ENTITIES.GLOBAL.ANC_CONFIG };
};