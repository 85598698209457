import { selectSettings,  } from "store/features/settingsSlice";
import { useSelector,  } from "react-redux";
export default function MatxLogo({ className }) {
  const settings = useSelector(selectSettings);
  const theme = settings.themes[settings.activeTheme];

  return (<svg 
    width="40px"
    height="40px"
    className={className}
    viewBox="0 0 32 47"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path d="M7.8465 20.5645H4.62743L12.6751 34.3264L21.93 17.9336H31.386L17.5037 42.4216H14.2847L26.9598 20.5645H23.5395L12.4739 39.7906L0 17.9336H9.45604L14.2847 26.0288L12.4739 28.6597L7.8465 20.5645Z" fill="#FFF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.0262 13.5938C25.9751 8.32947 21.3283 4.36265 15.7549 4.36265C10.1816 4.36265 5.53471 8.32947 4.48369 13.5938H0.0585938C1.15671 5.90827 7.76584 0 15.7549 0C23.744 0 30.3531 5.90827 31.4513 13.5938H27.0262Z" fill="#FF5F00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9438 15.1178C16.7216 15.1178 17.3521 14.4872 17.3521 13.7094C17.3521 12.9316 16.7216 12.3011 15.9438 12.3011C15.166 12.3011 14.5354 12.9316 14.5354 13.7094C14.5354 14.4872 15.166 15.1178 15.9438 15.1178ZM15.9438 17.9345C18.2772 17.9345 20.1688 16.0428 20.1688 13.7094C20.1688 11.376 18.2772 9.48438 15.9438 9.48438C13.6104 9.48438 11.7188 11.376 11.7188 13.7094C11.7188 16.0428 13.6104 17.9345 15.9438 17.9345Z" fill="#FF5F00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.99642 37.0645C6.17331 37.4852 5.97632 37.9699 5.55644 38.1472C4.56153 38.5671 3.81783 39.031 3.33567 39.4968C2.85558 39.9605 2.68902 40.3689 2.68902 40.7092C2.68902 41.0789 2.88727 41.5322 3.4687 42.0443C4.04872 42.5551 4.93542 43.0529 6.10221 43.4875C8.42951 44.3546 11.6998 44.9075 15.3525 44.9075C19.0052 44.9075 22.2754 44.3546 24.6027 43.4875C25.7695 43.0529 26.6562 42.5551 27.2362 42.0443C27.8177 41.5322 28.0159 41.0789 28.0159 40.7092C28.0159 40.3689 27.8494 39.9605 27.3692 39.4968C26.8871 39.031 26.1434 38.5671 25.1485 38.1472C24.7286 37.9699 24.5316 37.4852 24.7085 37.0645C24.8854 36.6438 25.3692 36.4464 25.7891 36.6237C26.8928 37.0896 27.8352 37.6505 28.5144 38.3066C29.1957 38.9647 29.6659 39.7752 29.6659 40.7092C29.6659 41.7271 29.1092 42.5957 28.3256 43.2859C27.5405 43.9774 26.4519 44.5625 25.1777 45.0371C22.6232 45.9888 19.1493 46.5607 15.3525 46.5607C11.5557 46.5607 8.08176 45.9888 5.52721 45.0371C4.25307 44.5625 3.16445 43.9774 2.37936 43.2859C1.59569 42.5957 1.03906 41.7271 1.03906 40.7092C1.03906 39.7752 1.50922 38.9647 2.19051 38.3066C2.86973 37.6505 3.81209 37.0896 4.91586 36.6236C5.33574 36.4464 5.81952 36.6438 5.99642 37.0645Z" fill="#FF5F00"/>
</svg>
)

  // return (
  //   <svg
  //     width="24px"
  //     height="24px"
  //     className={className}
  //     viewBox="0 0 240 239"
  //     version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     xmlnsXlink="http://www.w3.org/1999/xlink">
  //     <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
  //       <g
  //         id="logo"
  //         transform="translate(120.500000, 98.000000) rotate(-270.000000) translate(-120.500000, -98.000000) translate(-21.000000, -35.000000)">
  //         <g
  //           id="Group"
  //           transform="translate(141.500000, 133.000000) rotate(90.000000) translate(-141.500000, -133.000000) translate(9.000000, -8.000000)">
  //           <g
  //             id="Path-4-Copy"
  //             transform="translate(132.242532, 149.243361) rotate(-135.000000) translate(-132.242532, -149.243361) translate(38.742532, 55.743361)">
  //             <g id="path-1-link" fill="#000000">
  //               <polygon
  //                 id="path-1"
  //                 points="136.269985 0.8695976 186.615824 50.367072 186.615824 186.98041 50.7100649 186.98041 0.4346189 136.704964"></polygon>
  //             </g>
  //             <g id="path-1-link" fill={theme.palette.primary.light}>
  //               <polygon
  //                 id="path-1"
  //                 points="136.269985 0.8695976 186.615824 50.367072 186.615824 186.98041 50.7100649 186.98041 0.4346189 136.704964"></polygon>
  //             </g>
  //           </g>
  //           <polygon
  //             id="Path-3"
  //             fill={theme.palette.primary.light}
  //             points="13 238.415212 13 0 249.898437 238.415212"></polygon>
  //           <polygon
  //             id="Path-4"
  //             fill={theme.palette.primary.main}
  //             points="252.486992 0 252.486992 238.799226 13 238.799226"></polygon>
  //         </g>
  //       </g>
  //     </g>
  //   </svg>
  // );
}
