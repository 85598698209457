export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;


export const CODE = {
    OK_CODE: 200,
    CREATED: 201,
    BAD_REQUEST_CODE: 400,
    UNAUTHORIZED_CODE: 401,
    VALIDATION_CODE: 422,
    NO_CONTENT: 204,
    INTERNAL_SERVER_ERROR: 500
}



export const SNACKBAR = {
    TIMEOUT : 2000 ,
    DIRECTION : { vertical: 'top', horizontal: 'right' } ,
    TRANSITION : { direction: 'down' }

}

export const CRUD_ACTION = {
   LIST : "list",
   ADD : "add",
   EDIT : "edit",
   DELETE : "delete",
   DETAIL : "detail",
   CLEAR : "clear",

}

export const TOAST_STATUS = {
   ON : true,
   OFF : false,
 }


export const API_LOADING = {
  ON : true,
  OFF : false,
}

 export const CRUD_STATE = {
    list: [],
    count: 0,
    detail: {count: 0  , status : false , isList : false},
    del: { count: 0  , status : false  , isList : false},
    add: { count: 0,status : false  , isList : false },
    edit: { count: 0,status : false  , isList : false },
    loading: false,
    status: null,
    data: null,
    error: null,
    refetchList : true
  }


  export const PAGE_NOT_DELETE = ["about-us" ,"terms-and-conditions" ,"privacy-policy"]
  export const EMAIL_TEMPLATE_NOT_DELETE = ["email-verification" ,"forgot-password" ,"admin-forgot-password" ,"reset-password"]
  export const TESTIMONIAL_NOT_DELETE = ["email-verification" ,"forgot-password" ,"admin-forgot-password"]
  export const BANNER_NOT_DELETE = ["email-verification" ,"forgot-password" ,"admin-forgot-password"]
  export const ADVERTISEMENT_NOT_DELETE = ["email-verification" ,"forgot-password" ,"admin-forgot-password"]
  export const STATE_NOT_DELETE = ["United Kingdom" ,"Nigeria"]
  export const CITY_NOT_DELETE = [""]
  export const VENUE_FACILITY_NOT_DELETE = [""]
  export const VENUE_TYPE_NOT_DELETE = [""]
  export const ABOUT_NOT_DELETE = [""]
  export const ANCILLIARY_SERVICE_CATEGORY_NOT_DELETE = [""]
  export const BANK_NOT_DELETE = [""]
  export const PLAN_NOT_DELETE = [""]
  export const COUNTRY_NOT_DELETE = [""]

  export const ANCILLARY_SERVICE_NOT_DELETE = [""]
  export const VENUE_NOT_DELETE = [""]






  export const venueStatus = {
    PENDING: 0,
    SUCCESS: 1,
    FALIED: 2,
  };
  
  
  export const ancServiceApproveStatus = {
    PENDING: 0,
    SUCCESS: 1,
    FALIED: 2,
  };
  
  


  export const venueApproveStatus = {
    PENDING: 0,
    SUCCESS: 1,
    FALIED: 2,
  };


 