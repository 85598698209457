import { StorageConstant } from "constants/CommonConstants";
import { differenceInSeconds } from "date-fns";
import { removeItemFromStorage, setItemToStorage } from "./storageHelper";

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

function currentYPosition(elm) {
  if (!window && !elm) return;

  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}


export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}



export function persistAuthData  (data) {
  setItemToStorage(StorageConstant?.is_admin_access_token, data?.tokens?.access?.token);
  setItemToStorage(StorageConstant?.is_admin_refresh_token, data?.tokens?.refresh?.token);
  setItemToStorage(StorageConstant?.user_email, data?.user?.user_email);
}

export function clearAuthData  () {
  removeItemFromStorage(StorageConstant?.is_admin_access_token);
  removeItemFromStorage(StorageConstant?.is_user_profile);
  removeItemFromStorage(StorageConstant?.is_admin_refresh_token);
  removeItemFromStorage(StorageConstant?.user_email);
}


// Helper function to determine PAGE_SIZE_OPTIONS based on count
export function getPageSizeOptions (count)  {
  if (count <= 10 ) {
    return [10];
  } else if (count <= 25) {
    return [10, 25];
  } else if (count <= 50) {
    return [10, 25, 50];
  } else if (count <= 100) {
    return [10, 25, 50, 100];
  } else if (count <= 500) {
    return [10, 25, 50, 100 ,250];
  }else if (count <= 1000) {
    return [10, 25, 50, 100 , 250 ,500];
  }else {
    return [10, 25, 50,100 , 250 ,500 , 1000];
  }
};

  // Function to pluralize entity name
  export function pluralize(word) {
    if (!word || typeof word !== 'string') {
      throw new Error('Input must be a non-empty string');
    }
  
    // Regular expressions for common pluralization rules
    const pluralRules = [
      [/(m)an$/i, '$1en'],
      [/(pe)rson$/i, '$1ople'],
      [/(child)$/i, '$1ren'],
      [/(foot)$/i, 'feet'],
      [/(tooth)$/i, 'teeth'],
      [/(goose)$/i, 'geese'],
      [/(mouse)$/i, 'mice'],
      [/(ox)$/i, '$1en'],
      [/(quiz)$/i, '$1zes'],
      [/(s|x|z|ch|sh)$/i, '$1es'],
      [/([^aeiouy]|qu)y$/i, '$1ies'],
      [/([^f])fe$/i, '$1ves'],
      [/([lr])f$/i, '$1ves'],
      [/(hive)$/i, '$1s'],
      [/([^aeiouy]|qu)y$/i, '$1ies'],
      [/(tive)$/i, '$1s'],
      [/(o)$/i, '$1es'],
      [/(bus)$/i, '$1es'],
      [/(alias|status)$/i, '$1es'],
      [/(octop|vir)us$/i, '$1i'],
      [/(ax|test)is$/i, '$1es'],
      [/(sis)$/i, 'ses'],
      [/$/, 's'] // Default rule (add 's')
    ];
  
    for (const rule of pluralRules) {
      if (rule[0].test(word)) {
        return word.replace(rule[0], rule[1]);
      }
    }
  
    return word; // Fallback if no rule matches
  }

  export function appendToastStatus  (data, toastStatus ,apiLoading = false) {
    return { ...data, toastStatus ,apiLoading};
  };
  

  /**
 * Helper function to create FormData from form values.
 * @param {Object} values - The form values.
 * @returns {FormData} - The FormData object.
 */
export const createFormData = (values) => {
  let formData = new FormData();

  // Append all form fields to FormData
  for (let [key, value] of Object.entries(values)) {
    console.log("🚀 ~ createFormData ~ key:", key ,value)
    if (value instanceof Blob) {
      // Append Blob with filename if it's an image
      const defaultFilename = `blob.webp`; // You can customize the filename and extension
      formData.append(key, value,defaultFilename);
    } else {
      formData.append(key, value);
    }
  }

  return formData;
};


/**
 * Creates a FormData object and appends the provided values.
 * @param {Object} values - The values to append to the FormData.
 * @param {boolean} isEdit - Flag to determine if the form is in edit mode.
 * @returns {FormData} - The FormData object with appended values.
 */
export function createFormDataAddEdit(values, isEdit) {
  const data = new FormData();

  Object.keys(values).forEach(key => {
      if (key !== 'image' && key !== 'icon') {
          data.append(key, values[key]);
      }
  });

  // Append image if it's a new file (Blob) or if we're adding a new testimonial
  if (values.image instanceof Blob || !isEdit) {
    // const defaultFilename = `blob.webp`; // You can customize the filename and extension
    //   data.append('image', values.image ,defaultFilename);
    const defaultFilename = 'blob.webp'; // Customize the filename and extension as needed

    // Create a File object from the Blob with the default filename
    const imageFile = new File([values.image], defaultFilename, { type: values.image.type });
    data.append('image', imageFile);
  }


  if(values?.icon){
  // Append image if it's a new file (Blob) or if we're adding a new testimonial
  if (values?.icon instanceof Blob || !isEdit) {
    // const defaultFilename = `blob.webp`; // You can customize the filename and extension
    //   data.append('image', values.image ,defaultFilename);
    const defaultFilename = 'icon.svg'; // Customize the filename and extension as needed

    // Create a File object from the Blob with the default filename
    const iconFile = new File([values.icon], defaultFilename, { type: values.icon.type });
    data.append('icon', iconFile);
  }
  }
  


  return data;
}


export function createFormDataAddEditIcon(values, isEdit) {
  const data = new FormData();

  Object.keys(values).forEach(key => {
      if (key !== 'image') {
          data.append(key, values[key]);
      }
  });

  // Append image if it's a new file (Blob) or if we're adding a new testimonial
  if (values.image instanceof Blob || !isEdit) {
    // const defaultFilename = `blob.webp`; // You can customize the filename and extension
    //   data.append('image', values.image ,defaultFilename);
    const defaultFilename = 'icon.svg'; // Customize the filename and extension as needed

    // Create a File object from the Blob with the default filename
    const imageFile = new File([values.image], defaultFilename, { type: values.image.type });
    data.append('image', imageFile);
  }


  return data;
}





export async function createFormDataApi({values, is_image, image_type, isEdit}) {
  const formData = new FormData();

  // Append all form fields to formData
  Object.keys(values).forEach(key => {
    if (key !== 'images') {
      formData.append(key, values[key]);
    }
  });

  if (is_image && image_type === 'multiple') {
    const newImages = values.images.filter(img => img.isNew);
    await Promise.all(newImages.map((image, index) => 
      fetch(image.url)
        .then(res => res.blob())
        .then(blob => {  
          formData.append(`images`, blob, `image${index}.webp`);
        })
    ));
  }

  return formData;
}


export const formatUKNumber = (number) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};