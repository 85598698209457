export const MONTH_FORMATS = {
    FULL_MONTH_NAME: {
      formatSpecifier: 'MMMM',
      description: 'Full month name (e.g., January, February, March, ..., December)'
    },
    ABBREVIATED_MONTH_NAME: {
      formatSpecifier: 'MMM',
      description: 'Abbreviated month name (e.g., Jan, Feb, Mar, ..., Dec)'
    },
    TWO_DIGIT_MONTH: {
      formatSpecifier: 'MM',
      description: 'Two-digit month (01 for January, 02 for February, ..., 12 for December)'
    },
    MONTH_NO_LEADING_ZEROS: {
      formatSpecifier: 'M',
      description: 'Month without leading zeros (1 for January, 2 for February, ..., 12 for December)'
    }
  };


  export const DATE_FORMATS = {
    MONTH_DAY_YEAR_NUMERIC: {
      formatSpecifier: 'L',
      description: 'Month/day/year numeric (e.g., 3/19/2024)'
    },
    MONTH_NAME_DAY_YEAR_LONG: {
      formatSpecifier: 'LL',
      description: 'Month name day, year long (e.g., March 19, 2024)'
    },
    MONTH_NAME_ABBR_DAY_YEAR_TIME: {
      formatSpecifier: 'LLL',
      description: 'Month name abbr., day, year, time (e.g., Mar 19, 2024 12:00 AM)'
    },
    WEEKDAY_MONTH_NAME_DAY_YEAR_TIME_LONG: {
      formatSpecifier: 'llll',
      description: 'Weekday, month name day, year, time long (e.g., Monday, March 19, 2024 12:00 AM)'
    }
  };
  
  
  // dayFormats.js

export const DAY_FORMATS = {
    FULL_DAY_NAME: {
      formatSpecifier: 'dddd',
      description: 'Full day name (e.g., Sunday, Monday, ..., Saturday)'
    },
    ABBREVIATED_DAY_NAME: {
      formatSpecifier: 'ddd',
      description: 'Abbreviated day name (e.g., Sun, Mon, ..., Sat)'
    },
    TWO_DIGIT_DAY_OF_MONTH: {
      formatSpecifier: 'DD',
      description: 'Two-digit day of the month (01 for 1st day, 02 for 2nd day, ..., 31 for 31st day)'
    },
    DAY_OF_MONTH_NO_LEADING_ZEROS: {
      formatSpecifier: 'D',
      description: 'Day of the month without leading zeros (1 for 1st day, 2 for 2nd day, ..., 31 for 31st day)'
    }
  };
  

  export const YEAR_FORMATS = {
    FULL_YEAR: {
      formatSpecifier: 'YYYY',
      description: 'Four-digit year (e.g., 2024)'
    },
    TWO_DIGIT_YEAR: {
      formatSpecifier: 'YY',
      description: 'Two-digit year (e.g., 24 for 2024)'
    }
  };



  // timeFormats.js

export const TIME_FORMATS = {
    HOUR_24: {
      formatSpecifier: 'HH',
      description: 'Hour in 24-hour format (00 to 23)'
    },
    HOUR_12: {
      formatSpecifier: 'hh',
      description: 'Hour in 12-hour format (01 to 12)'
    },
    MINUTE: {
      formatSpecifier: 'mm',
      description: 'Minute (00 to 59)'
    },
    SECOND: {
      formatSpecifier: 'ss',
      description: 'Second (00 to 59)'
    },
    MILLISECONDS: {
      formatSpecifier: 'SSS',
      description: 'Millisecond (000 to 999)'
    },
    AM_PM_UPPERCASE: {
      formatSpecifier: 'A',
      description: 'AM/PM in uppercase (AM or PM)'
    },
    AM_PM_LOWERCASE: {
      formatSpecifier: 'a',
      description: 'AM/PM in lowercase (am or pm)'
    }
  };
  

export const DAY = {
    DAY_NAME : "dddd",
    MONTH_NAME : "MMMM",
    YEAR_NAME : "YYYY",
}

export const DATE = {
    DEFAULT_DATE :  'DD/MM/YYYY',        //DEFAULT Date format (e.g.,March 19, 2024	)
    FULL_DATE: 'YYYY-MM-DD',         // Full date (e.g., 2024-03-19)
    MONTH_DAY_YEAR: 'MMMM DD, YYYY', // Month, day, and year (e.g., March 19, 2024)
    DAY_MONTH_YEAR: 'DD MMMM YYYY',  // Day, month, and year (e.g., 19 March 2024)
    SHORT_DATE: 'MM/DD/YYYY',        // Short date format (e.g., 03/19/2024)
    LONG_DATE: 'MMMM Do, YYYY',      // Long date format with ordinal (e.g., March 19th 2024)
    MM_DD_YYYY: 'MM DD YYYY',        // Date format (e.g., 03 19 2024)
    DD_MM_YYYY: 'DD MM YYYY',        // Date format (e.g., 19 03 2024)
    CUSTOM_FORMAT: `${DAY_FORMATS.FULL_DAY_NAME} ${MONTH_FORMATS.FULL_MONTH_NAME} ${DAY_FORMATS.TWO_DIGIT_DAY_OF_MONTH}` ,   // Custom format (e.g., March Wednesday 20)
    CUSTOM_FORMAT_SHOW: `[The] ${DAY_FORMATS.FULL_DAY_NAME} ${MONTH_FORMATS.FULL_MONTH_NAME} ${DAY_FORMATS.TWO_DIGIT_DAY_OF_MONTH}`    // Custom format (e.g., March Wednesday 20)

  };
  
  export const TIME = {
    DEFAULT_TIME :  'hh:mm A',    //Time format with AM/PM (e.g., 5:32:14 pm)
    SHORT_TIME: 'HH:mm',     // Short time format (e.g., 17:32)
    FULL_TIME: 'HH:mm:ss',   // Full time format (e.g., 17:32:14)
    AM_PM: 'h:mm:ss a'       // Time format with AM/PM (e.g., 5:32:14 pm)
  };
  
  export const DATETIME = {
    DEFAULT_DATETIME :  'DD/MM/YYYY h:mm A',        //DEFAULT ISO 8601 datetime format (e.g., 2024-03-19T17:32:14Z)
    ISO_8601: 'YYYY-MM-DDTHH:mm:ssZ',  // ISO 8601 datetime format (e.g., 2024-03-19T17:32:14Z)
    SHORT_DATE_TIME: 'MM/DD/YYYY HH:mm', // Short datetime format (e.g., 03/19/2024 17:32)
    LONG_DATE_TIME: 'MMMM Do YYYY, h:mm:ss a' // Long datetime format (e.g., March 19th 2024, 5:32:14 pm)
  };
  